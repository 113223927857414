import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'

interface SectionHeadingProps {
  tag?: string
  heading?: string
  leftAlignDesk?: boolean
  leftAlignMobile?: boolean
}

const SectionHeading: FunctionComponent<SectionHeadingProps> = ({
  leftAlignDesk,
  leftAlignMobile,
  tag,
  heading,
}) => {
  return (
    <Container>
      <TopText
        AlignDesktop={leftAlignDesk ? 'left' : 'center'}
        AlignMobile={leftAlignMobile ? 'left' : 'center'}
      >
        {tag && <Tag className="small-p">{tag}</Tag>}
        {heading && <h2>{heading}</h2>}
      </TopText>
    </Container>
  )
}

export default SectionHeading

interface IAlignment {
  AlignDesktop: string
  AlignMobile: string
}

const Container = styled.div`
  // border-bottom: 1px solid ${theme.grey.keyline};
`

const TopText = styled.div<IAlignment>`
  margin-bottom: 25px;
  padding-left: 20px;
  @media (min-width: 768px) {
    margin-bottom: 4.7rem;
    text-align: ${(props) => props.AlignDesktop};
  }
  @media (max-width: 768px) {
    margin-bottom: 4.7rem;
    text-align: ${(props) => props.AlignMobile};
  }
`

const Tag = styled.p`
  color: ${theme.grey.inputHover};
  margin-bottom: 10px;

  @media (min-width: 768px) {
    margin-bottom: 17px;
  }
`
