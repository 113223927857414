import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import theme from '../styling/theme'
import arrow from '../../images/link-arrow.svg'
import { appendUrlPartnerTracking } from '../../util/url'

interface InlineLinkInterface {
  href: string
  text: string
  marginbottom?: string
  margintop?: string
  margintopDesktop?: string
  icon?: boolean
  hovercolor?: string
}

const InlineLink: FunctionComponent<InlineLinkInterface> = ({
  href,
  text,
  marginbottom,
  margintop,
  margintopDesktop,
  icon,
  hovercolor,
}) => {
  return (
    <Wrapper
      marginbottom={marginbottom}
      margintop={margintop}
      margintopDesktop={margintopDesktop}
    >
      <StyledLink to={appendUrlPartnerTracking(href)} hovercolor={hovercolor}>
        {text}
      </StyledLink>
      {icon && <Img src={arrow} alt="Arrow link" style={{ width: 30 }} />}
    </Wrapper>
  )
}

export default InlineLink

interface WrapperInterface {
  margintop?: string
  marginbottom?: string
  margintopDesktop?: string
}
const Wrapper = styled.div<WrapperInterface>`
  display: flex;
  align-items: center;
  margin-top: ${(props) => props.margintop && props.margintop};
  margin-bottom: ${(props) => props.marginbottom && props.marginbottom};
  @media (min-width: 768px) {
    margin-top: ${(props) => props.margintopDesktop && props.margintopDesktop};
  }
`
interface StyledLinkInterface {
  hovercolor?: string
}
const StyledLink = styled(Link)<StyledLinkInterface>`
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  font-weight: bold;
  color: ${theme.brand.blue};

  &:hover,
  &:active {
    color: ${(props) =>
      props.hovercolor ? props.hovercolor : theme.text.second};
    text-decoration: none;
  }
`
const Img = styled.img`
  width: 40px;
  height: 15px;
  margin-left: 18px;
  display: inline-block;
  margin-top: 3px;
  transition: all 0.3s ease;
  ${Wrapper}:hover & {
    margin-left: 24px;
  }
`
