import React, { useLayoutEffect, useState, FunctionComponent } from 'react'
import styled from 'styled-components'
import InlineLink from './shared/InlineLink'
import arr from '../images/link-arrow.svg'
import arrHovered from '../images/link-arrow-red.svg'
import theme from './styling/theme'
import Cursor from './Cursor'
import SectionHeading from './shared/SectionHeading'
import VerticalPadding from './styling/VerticalPadding'
import SwiperCore, { Navigation, Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Img from 'gatsby-image'

import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'

// install Swiper components
SwiperCore.use([Navigation, Scrollbar])

interface Item {
  img: string
  alt: string
  content: string
  url: string
  initials: string
  author: string
  smallText?: string
  vertical?: boolean
}

interface CarouselProps {
  data: Array<Item>
  heading: string
  id: string
  readMore?: boolean
}
const CarouselDrag: FunctionComponent<CarouselProps> = ({
  data,
  heading,
  id,
  readMore,
}) => {
  const [slides, setSlides] = useState(3)
  const [padding, setPadding] = useState(25)
  const [charLimit, setCharLimit] = useState(175)

  const formatText = (text: string) => {
    if (text.length < charLimit) {
      return text
    }
    return text.substr(0, charLimit) + '...'
  }
  useLayoutEffect(() => {
    function updateSize() {
      if (typeof window !== 'undefined') {
        if (window.innerWidth <= 767) {
          setSlides(window.innerWidth / (300 + padding * 3))
        } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
          setSlides(window.innerWidth / (360 + padding * 2.2))
        } else if (window.innerWidth >= 1024 && window.innerWidth < 1200) {
          setSlides(window.innerWidth / (360 + padding * 2.2))
        } else {
          setSlides(3.2)
        }
      }
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return (
    <Container>
      <VerticalPadding>
        <Cursor id={id} />
        <SectionHeading heading={heading} center />
        <SwiperContainer id={id}>
          <Swiper
            className="swiper-container"
            slidesPerView={slides}
            navigation
            scrollbar={
              ({ draggable: true },
              { el: '.swiper-scrollbar' },
              { snapOnRelease: true })
            }
          >
            <SwipperWrapper
              id="carousel-drag-container"
              className="swiper-wrapper"
            >
              {data.map((item, i) => {
                return (
                  <SwiperSlide key={i} className="carousel-drag-slide">
                    <ImgContainer>
                      <ImgMask vertical={item.acfTestimonial.vertical}>
                        <Image
                          vertical={item.acfTestimonial.vertical}
                          fluid={
                            item.acfTestimonial.logo.localFile.childImageSharp
                              .fluid
                          }
                          alt={item.alt}
                        />
                      </ImgMask>
                    </ImgContainer>
                    {item.acfTestimonial.description && (
                      <Content className="hero-p">
                        {formatText(item.acfTestimonial.description)}
                      </Content>
                    )}
                    {item.slug && readMore != false ? (
                      <InlineLink
                        href={'/customer-stories/' + item.slug}
                        text={'Read more'}
                        marginbottom="33px"
                        icon
                        margintopDesktop="auto"
                      />
                    ) : null}
                    <Line />
                    <Bottom>
                      {item.acfTestimonial.initials && (
                        <InitialsCircle>
                          {item.acfTestimonial.initials}
                        </InitialsCircle>
                      )}
                      {item.acfTestimonial.author && (
                        <Author>{item.acfTestimonial.author}</Author>
                      )}
                      {item.title && (
                        <Legal className="legal-text">{item.title}</Legal>
                      )}
                    </Bottom>
                  </SwiperSlide>
                )
              })}
            </SwipperWrapper>
          </Swiper>
        </SwiperContainer>
      </VerticalPadding>
    </Container>
  )
}

export default CarouselDrag

const Container = styled.section`
  background: ${theme.background.grey};
  padding: 0 20px 50px;
  overflow: hidden;
  @media (min-width: 768px) {
    padding: 0 3% 50px;
  }
  @media (min-width: 1200px) {
    padding: 0 0 50px;
  }
  .swiper-container {
    overflow: visible;
    transition: transform 0.8s ease;
    width: 100%;
    margin-right: auto;
    transition: transform 0.4s ease;
    @media (min-width: 1024px) {
      width: 100%;
    }
  }
  .carousel-drag-slide {
    padding: 43px 40px 30px;
    background: white;
    width: 360px !important;
    height: auto;
    transition: transform 1s ease;
    margin-right: 20px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media screen and (max-width: 768px) {
      width: 320px !important;
    }
  }

  .swiper-scrollbar {
    position: absolute;
    height: 1px;
    width: 70%;
    background: ${theme.grey.scroll};
    left: 100px;
    bottom: -40px;
    @media screen and (min-width: 768px) {
      width: 85%;
    }
    @media screen and (min-width: 1200px) {
      width: 92%;
    }
  }
  .swiper-scrollbar-drag {
    height: 3px;
    position: absolute;
    top: -1px;
    background: ${theme.brand.blue};
  }

  .swiper-button-next {
    margin-left: 18px;
    left: 18px;
  }

  .swiper-button-prev {
    transform: rotate(-180deg);
    left: 0;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    opacity: 0;
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: auto;
    bottom: -45px;
    width: 4.9rem;
    height: 1rem;
    margin: 0;
  }
  .swiper-button-next {
    left: 40px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    background: url(${arr});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 30px;
  }
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    background: url(${arrHovered});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 30px;
  }
  .swiper-button-disabled {
    opacity: 0.5;
    cursor: auto;
    pointer-events: none;
  }
`
const ArrowsContainer = styled.div`
  display: flex;
  position: relative;
  top: auto;
  bottom: -35px;
`
const SwiperContainer = styled.div`
  cursor: grab;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
`
const SwipperWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
`
interface ImageProps {
  vertical?: boolean
}
const Image = styled(Img)<ImageProps>`
  width: ${(props) => (props.vertical ? '70px' : '140px')};
  height: auto;
`
const ImgMask = styled.div<ImageProps>`
  height: ${(props) => (props.vertical ? '80px' : '40px')};
  width: 100%;
`
const ImgContainer = styled.div`
  height: 80px;
  max-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 28px;
  /* border: 1px solid red; */
`

const Content = styled.p`
  margin-bottom: 40px;
  font-weight: 300;
  margin-top: auto;
`
const Line = styled.div`
  background: ${theme.grey.keylineDark};
  height: 1px;
  width: 100%;
`

const InitialsCircle = styled.div`
  color: ${theme.text.white};
  font-family: Montserrat;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.grey.inputHover};
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-bottom: 13px;
`

const Author = styled.p`
  font-family: Montserrat;
  font-weight: bold;
`

const Legal = styled.p`
  color: ${theme.text.darkMain};
  opacity: 0.8;
`
const Bottom = styled.div`
  margin: 30px 0 auto;
  height: 110px;
`
